.marine-group {
    font-family: "Open Sans", sans-serif;
    font-optical-sizing: auto;
    font-weight: 350;
    font-style: normal;
    font-variation-settings: "wdth" 100;
    color: #353C40;
    font-size: 18px;
}

.marine-group .sub-group {
    font-weight: 500;
}

.marine-group-default {
    color: white;
}
.marine-group-corals {
    color: #353C40;
}