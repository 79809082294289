.header {
    font-family: "Open Sans", sans-serif;
    font-optical-sizing: auto;
    font-weight: 500;
    font-style: normal;
    font-variation-settings: "wdth" 100;
    background: white;
    color: #0041BE;
    text-align: center;
    line-height: 34px;
    height: 100px;
}


.header .main {
    font-size: 24px;
}

.header .secondary {
    font-size: 16px;
    color: #000000;
}